import BaseTopNav from '@/components/base/baseTopNav';
import ChangePasswordForm from './components/ChangePasswordForm';
import {ChangePasswordTips} from './components/ChangePasswordTips';
import {useChangePasswordStateHooks} from './useStateHooks';
import {useTranslation} from 'react-i18next';

/**
 * 更换密码页面
 * */
export default () => {
  const props = useChangePasswordStateHooks();
  const { t } = useTranslation();
  return (
    <>
      <BaseTopNav title={t('修改密码')} />
      <div className="p-4">
        <ChangePasswordTips></ChangePasswordTips>
      </div>
      <div>
        <ChangePasswordForm {...props}></ChangePasswordForm>
      </div>
    </>
  );
};
