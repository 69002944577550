import { changeUserPasswordApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { history } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

export const useChangePasswordStateHooks = () => {
  // 原密码
  const [originalPassword, setOriginalPassword] = useState('');
  // 新密码
  const [newPassword, setNewPassword] = useState('');
  // 确认密码
  const [repeatPassword, setRepeatPassword] = useState('');
  const { t } = useTranslation();

  const { run } = useReq(changeUserPasswordApi, {
    manual: true,
    onSuccess: (res) => {
      history.back();
    },
  });

  //提交
  const submit = () => {
    if (newPassword.trim() !== repeatPassword.trim())
      return toast.error(t('请输入两次相同的密码'));
    run({ originalPassword, newPassword, repeatPassword });
  };
  return {
    originalPassword, // 原密码
    setOriginalPassword, // 更新原密码
    newPassword, // 新密码
    setNewPassword, // 更新新密码
    repeatPassword, // 确认密码
    setRepeatPassword, // 更新确认密码
    submit, // 提交
  };
};

export type ChangePasswordState = ReturnType<
  typeof useChangePasswordStateHooks
>;
