import BaseInput from '@/components/base/baseInput';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { ChangePasswordState } from '../useStateHooks';
import { useState } from 'react';
import { useReq } from '@/services/net/request';
import { changeUserPasswordApi } from '@/services/api/requestApi';
import { history } from '@@/core/history';
import { toast } from '@/layouts/components/ToastMessage';
import { useReactive } from 'ahooks';

/**
 * 变更密码组件
 *
 * @param {ChangePasswordState} props 组件接收的props，包含以下字段：
 *        setOriginalPassword: 设置原密码的函数
 *        originalPassword: 原密码值
 *        newPassword: 新密码值
 *        setNewPassword: 设置新密码的函数
 *        repeatPassword: 确认新密码值
 *        setRepeatPassword: 设置确认新密码的函数
 *        submit: 提交表单的函数
 * @returns 返回变更密码表单的JSX元素
 */
export default () => {
  const { t } = useTranslation();
  const form = useReactive({
    originalPassword:'',
    newPassword:'',
    repeatPassword:'',
  })


  const { run } = useReq(changeUserPasswordApi, {
    manual: true,
    onSuccess: (res) => {
      history.back();
    },
  });

  //提交
  const submit = () => {
    if (form.newPassword.trim() !== form.repeatPassword.trim())
      return toast.error(t('请输入两次相同的密码'));
    run({ ...form });
  };
  // 返回密码变更表单的UI结构
  return (
    <div className="px-4 ">
      <div className="my-3">
        <div className="flex items-center font-bold">{t('原密码')}</div>
        <BaseInput
          className="mt-2"
          placeholder={t('原密码')}
          isRequired
          type="password"
          value={form.originalPassword}
          onChange={(e) => {
            form.originalPassword = e.target.value;
          }}
        />
      </div>
      <div className="my-3">
        <div className="flex items-center font-bold">{t('新密码')}</div>
        <BaseInput
          className="mt-2"
          placeholder={t('新密码')}
          isRequired
          type="password"
          value={form.newPassword}
          onChange={(e) => {
            form.newPassword = e.target.value;
          }}
        />
      </div>
      <div className="my-3">
        <div className="flex items-center font-bold">{t('确认密码')}</div>
        <BaseInput
          className="mt-2"
          placeholder={t('确认密码')}
          isRequired
          type="password"
          value={form.repeatPassword}
          onChange={(e) => {
            form.repeatPassword = e.target.value;
          }}
        />
      </div>
      <div className="my-3">
        <Button className="mt-6 mainColorButton" onClick={submit}>
          {t('确认')}
        </Button>
      </div>
    </div>
  );
};
