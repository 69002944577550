import { F7ExclamationmarkCircleFill } from '@/assets/icons/comm/F7ExclamationmarkCircleFill';
import { useTranslation } from 'react-i18next';

/**
 * 显示更改密码操作的提示信息
 * 该组件无参数。
 * @returns 返回一个包含密码修改安全提示的 JSX 元素。
 */
export function ChangePasswordTips() {
  const { t } = useTranslation(); // 使用 i18n 库获取翻译函数

  return (
    // 创建一个显示密码修改安全提示的容器
    <div className="flex gap-2 p-4 w-full gap-y-10 bg-primary/30   rounded-md">
      <span>
        {/*显示一个警告图标*/}
        <F7ExclamationmarkCircleFill className=" text-warningColor"></F7ExclamationmarkCircleFill>
      </span>
      <div className=" font-bold text-sm ">
        {/*显示密码修改的安全提示文本*/}
        {t(
          '您即将进行密码的修改操作，请确保您处于安全网络环境，并且仅在私人设备上进行此项操作，以保护您的账户安全。',
        )}
      </div>
    </div>
  );
}
